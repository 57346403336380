import { IconButton, } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Stack, Typography } from '@mui/material';
import { useBizTheme } from '../../hooks/useBizTheme';
import AppButton from '../AppButton/AppButton';
import classes from './ConfirmSaveForLater.module.scss';

const ConfirmSaveForLater = ({open,onOk, onCancel}) => {
      const theme = useBizTheme();
    
  return open && (
    <div className={classes.sheet}>

            <div className={classes.header} style={{ backgroundColor: theme.color }}>

            <IconButton aria-label="" onClick={onCancel}>
                <ArrowBackIos style={{ color: "white" }} />
            </IconButton>

            <Typography variant="h5" className={classes.title}>Confirm Save Order for Later</Typography>

            </div>

            <Stack sx={{width: '100%',height: '100%', alignItems: 'center', justifyContent: 'center'}} spacing={4}>
                                            
                <Typography sx={{ width: '100%',textAlign: 'center', paddingX: 2}} variant="h6">
                 Are you sure you want to save your order for later?
                </Typography>
                <Stack spacing={2} direction='row' sx={{width: '100%', paddingX: 2}}>
                    <AppButton  onClick={onOk}>Ok</AppButton>
                    <AppButton isOutlined  onClick={onCancel}>Cancel</AppButton>
                </Stack>
            </Stack> 

        </div>
  )
}

export default ConfirmSaveForLater