import { Button, ButtonProps, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CSSProperties, useMemo } from "react";
import classes from "./AppButton.module.scss";

interface AppButtonProps extends ButtonProps {
    children: React.ReactNode
    isOutlined?: boolean;
}
const defaultButtonStyle: CSSProperties = {
    msTransition: "background 0.2s ease-in-out",
    transition: "background 0.2s ease-in-out",
    width: 300,
    borderRadius: 100,
    height: 50,
    fontSize: 20,
    fontWeight: 500,
}

const smallScreenButtonStyle: CSSProperties = {
    msTransition: "background 0.2s ease-in-out",
    transition: "background 0.2s ease-in-out",
    width: 300,
    borderRadius: 100,
    height: 50,
    fontSize: 19,
    fontWeight: 500,
}

const AppButton = (props: AppButtonProps) => {
    const matchSmallScreen = useMediaQuery("(max-width:600px)");

    const { onClick, isOutlined = false, children, ...restOfProps } = props;
    const theme = useTheme();

    const variant = isOutlined ? "outlined" : "contained";
    const isTextVariant = props.variant === "text";

    const BtnStyle = useMemo(() => {
        let style: CSSProperties = {
            color: props.disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.primary.main,
            textTransform: "none",
            ...props.style,
        }

        if (matchSmallScreen) {
            style = { ...smallScreenButtonStyle, ...style };
        } else {
            style = { ...defaultButtonStyle, ...style };
        }

        if (isTextVariant) {
            return style;
        }

        if (!isOutlined) {
            style.color = "white";
            style.backgroundColor = theme.palette.primary.main;
        }

        return style;
    }, [matchSmallScreen, props.style, props.disabled, isTextVariant, theme.palette.primary])

    const Children = useMemo(() => {
        if (props.variant !== "text") {
            return children;
        }

        return <Typography className={classes.label}>
            {children}
        </Typography>
    }, [props.style, props.variant, theme.palette.primary])

    return (
        <Button
            color="primary"
            onClick={onClick}
            className={classes.buttonWrapper}
            variant={variant}
            {...restOfProps}
            style={BtnStyle}
        >
            {Children}
        </Button>
    );
}

export default AppButton;