import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  Snackbar,
} from "@material-ui/core";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useClipboard } from "../../hooks/useClipboard";

const ShareButton = ({ url }: { url: string }) => {
  if (!navigator.share) {
    return null;
  }
  const openShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url,
        });
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    }
  };
  return (
    <Button sx={{ width: "auto" }} variant="contained" onClick={openShare}>
      <IosShareIcon />
    </Button>
  );
};

const ShareTracking = ({
  orderTrackingId,
  sourceApp,
}: {
  orderTrackingId: string;
  sourceApp: string;
}) => {
  const clipboard = useClipboard();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (sourceApp) setUrl(`${sourceApp}/order/${orderTrackingId}`);
  }, [orderTrackingId, sourceApp]);

  return (
    <Paper
      elevation={0}
      style={{ position: "relative", background: "transparent" }}
    >
      <Stack
        spacing={1}
        sx={{
          padding: 1,
        }}
      >
        <FormControl variant="standard" style={{ flex: 1 }}>
          <InputLabel shrink>Sharable Link</InputLabel>
          <Input
            readOnly
            fullWidth
            placeholder="Sharable Link"
            value={url ?? ""}
          />
         
        </FormControl>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => clipboard.copy(url)}
          >
            Copy
          </Button>

          <ShareButton url={url!} />
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={clipboard.copied}
        message="Url copied to clipboard"
        key="buttomcenter"
      />
    </Paper>
  );
};

export default ShareTracking;
