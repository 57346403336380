import "./MuiOverrides.scss";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import TrackingPage from "./components/OrderTracking/OrderTracking";
import OrderingApp from "./pages/ordering/OrderingApp";
import FoodCourt from "./pages/foodCourt/FoodCourt";
import AccountRoute from "./pages/account/AccountRoute";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import DateFnsUtils from "@date-io/date-fns";
import { GlobalLoader } from "./components/Loader/Loader";


const RootRouter = () => {
  return (
    <Router>
      <Switch>


        <Route exact path="/">
          <FoodCourt />
          <GlobalLoader />
        </Route>

        <Route path="/account" >
          <AccountRoute />
          <GlobalLoader />
        </Route>

        <Route exact path="/order/:orderTrackingId/:userId?">
          <TrackingPage />
          <GlobalLoader />
        </Route>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Route path={"/:businessName"} component={OrderingApp} />
          <GlobalLoader />
        </MuiPickersUtilsProvider>

      </Switch>
    </Router>
  )
}

export default RootRouter;
