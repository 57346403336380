import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OrderSubmissionResponse,
  saveOrderForLater,
  submitOrder,
} from "../../api/order";
import { getOrderMode } from "../../common/app";
import {
  calculatePaperBagFee,
  checkGiftCardPurchasable,
} from "../../common/order";
import { calculateDriverTip, calculateTip } from "../../common/price/price";
import { OrderMode, freeDeliveryLabel } from "../../constants";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { orderSubmissionPopupAutoCloseTime as defaultOrderSubmissionPopupAutoCloseTime } from "../../serverConfig";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE } from "../../Types/Types";
import AppButton from "../AppButton/AppButton";
import CreditCardPayForm from "../CreditCardPayForm/CreditCardPayForm";
import Loader from "../Loader/Loader";
import classes from "./ConfirmOrder.module.scss";
import ConfirmSaveForLater from "./ConfirmSaveForLater";
import CreditPayButton from "./CreditPayButton";
import usePriceAdjustments from "./hooks/usePriceDetails";
import KioskOrderConfirmationPage from "./KioskOrderConfirmationPage";
import { PaymentRow, PaymentRowOptions } from "./PaymentRow";

function tryParseJson<T extends Object>(jsonString?: string) {
  try {
    const payKeyObject: T = JSON.parse(jsonString ?? "");
    return payKeyObject;
  } catch (error) {
    return false;
  }
}

interface ConfirmOrderProps {
  dismissModal: Function;
  handleNext: Function;
  handleBack: Function;
}

const ConfirmOrder = (props: ConfirmOrderProps) => {
  const myRef = useRef<HTMLInputElement>(null);
  const { handleNext, handleBack } = props;
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;

  const history = useHistory();
  const { setDiscountsToFinalOrder, setEnableBrowserBackModal } = useActions();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const finalOrder = useTypedSelector((state) => state.orderDetails.finalOrder);
  const { isSignedIn } = useTypedSelector((state) => state.account);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState("");
  const [saveForLaterConfirm, setSaveForLaterConfirm] = useState<boolean>(false)

  const [showSubmittedPopup, setShowSubmittedPopup] = useState(false);
  const [orderNum, setOrderNum] = useState<number>(0);
  const [autoCloseRemainingTime, setAutoCloseTime] = useState<number>(
    defaultOrderSubmissionPopupAutoCloseTime
  );

  const isGiftCardPurchasable = checkGiftCardPurchasable(
    finalOrder,
    businessInfo
  );
  const isDeliveryOrder = finalOrder.orderType === "delivery";

  const {
    surcharges,
    highestDiscount,
    calculateCreditAmt,
    normalizeDiscounts,
  } = usePriceAdjustments();
  const { showCreditCardForm, setShowCreditCardForm } = usePriceAdjustments();

  /** For testing in development */
  // useEffect(() => {
  //   const interval = startAutoClose();
  //   return () => { window.clearInterval(interval); }
  // }, [])

  useEffect(() => {
    myRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (!businessInfo?.kioskOrderConfirmation?.autoCloseSeconds) return;
    setAutoCloseTime(businessInfo.kioskOrderConfirmation.autoCloseSeconds);
  }, [businessInfo?.kioskOrderConfirmation?.autoCloseSeconds]);

  const { payableAmt, creditDiscount, tip, driverTip } = useMemo(() => {
    const { priceAdjustments } = surcharges;
    const { creditAmtToApply, creditDsc } = calculateCreditAmt(
      finalOrder.total
    );

    const tip = calculateTip(finalOrder);
    const driverTip = calculateDriverTip(finalOrder);
    const payableAmt = finalOrder.total - creditAmtToApply;

    const dscs = normalizeDiscounts(priceAdjustments);
    if (highestDiscount) {
      dscs.push({
        value: highestDiscount.value,
        title: highestDiscount.title,
        type: highestDiscount.type,
        amount: highestDiscount.amount,
        discountType: highestDiscount.discountType,
        maxPurchase: highestDiscount.maxPurchase,
      });
    }

    if (creditDsc) {
      dscs.push({
        value: creditDsc.amount,
        title: creditDsc.title,
        type: creditDsc.type,
        amount: creditDsc.amount,
        discountType: creditDsc.discountType,
        maxPurchase: creditDsc.maxPurchase,
      });
    }

    setDiscountsToFinalOrder(dscs);

    return {
      tip,
      driverTip,
      payableAmt,
      creditDiscount: creditDsc,
    };
  }, [
    businessInfo,
    highestDiscount,
    finalOrder.total,
    normalizeDiscounts,
    calculateCreditAmt,
    surcharges,
  ]);

  const SurchargeRows = useMemo(() => {
    const { priceAdjustments } = surcharges;
    if (!priceAdjustments) return;

    const priceAdjRows = priceAdjustments
      .filter((d) => d.discountType !== DISCOUNT_TYPE.credit)
      .map((discount) => {
        const isPercentage = discount.type === DISCOUNT_VALUE_TYPE.percentage;
        const percent = isPercentage ? discount.value : undefined;

        const isDiscount = discount.amount >= 0;
        const absValue = isDiscount ? discount.amount : -discount.amount;

        return (
          <PaymentRow
            label={discount.title}
            value={absValue}
            percent={percent}
            isDiscount={isDiscount}
          />
        );
      });

    return priceAdjRows;
  }, [businessInfo, surcharges]);

  const isPayInPersonEnabled = !businessInfo?.payInPersonDisabled;

  const PaperBagRow = useMemo(() => {
    if (
      finalOrder?.paperBagCount === null ||
      finalOrder.paperBagCount === undefined
    ) {
      return null;
    }

    const paperBagFee = calculatePaperBagFee(finalOrder);
    if (paperBagFee === 0) {
      return null;
    }

    return (
      <PaymentRow
        label="Number of Paper Bags(s)"
        value={paperBagFee}
        alternated={true}
      />
    );
  }, [
    finalOrder.paperBagFee,
    finalOrder.paperBagCount,
    businessInfo?.paperBagFee,
  ]);

  const startAutoClose = () => {
    setShowSubmittedPopup(true);
    setEnableBrowserBackModal(false);
    let intervalId = setInterval(() => {
      setAutoCloseTime((seconds) => {
        if (seconds === 0) {
          window.clearInterval(intervalId);
          onClosePopup();
          return seconds;
        }
        return seconds - 1;
      });
    }, 1000);

    return intervalId;
  };

  const handleOrderSubmission = useCallback(async () => {
    setLoading(true);
    const res = (await submitOrder(finalOrder, {
      total: payableAmt,
      businessInfo,
    })) as OrderSubmissionResponse;

    setLoading(false);

    if (!res.success) {
      setPaymentError(res as string);
      return;
    }

    if (isKioskViewMode) {
      setOrderNum(res.orderNumber!);
      startAutoClose();
    } else {
      history.push(`/order/${res.orderTrackingId}`);
    }
  }, [payableAmt, finalOrder, businessInfo]);

  const CreditCardPayModes = useMemo(() => {
    const isOverMinMobilePayAmt = payableAmt >= 100;
    const isMobilePayEnabled = !businessInfo?.mobilePaymentDisabled;
    const paykeyObject = tryParseJson<{ applicationId }>(
      businessInfo?.payKey ?? ""
    );
    const isPayKeyValid =
      paykeyObject !== false && !!paykeyObject.applicationId;

    if (!isOverMinMobilePayAmt) {
      return (
        <div className={classes.creditCardUnavailable}>
          Credit Card is not accepted for payments under $1
        </div>
      );
    }

    if (!isMobilePayEnabled) return;
    if (!isPayKeyValid) return;

    return (
      <AppButton
        className={classes.pay_in_person__buttons_creditCardPay}
        onClick={(e) => {
          setShowCreditCardForm(true);
        }}
      >
        Pay now
      </AppButton>
    );
  }, [payableAmt, businessInfo?.mobilePaymentDisabled, businessInfo?.payKey]);

  const PayInPersonButton = useMemo(
    () => <AppButton onClick={handleOrderSubmission}>Pay in person</AppButton>,
    [handleOrderSubmission]
  );

  const onClosePopup = useCallback(() => {
    setShowSubmittedPopup(false);
    setLoading(true);
    window.location.reload();
  }, [window]);

  const EditOrderButton = useMemo(
    () => (
      <AppButton isOutlined onClick={() => handleBack(false)}>
        Edit order
      </AppButton>
    ),
    [handleBack]
  );

  const handleSaveForLater = useCallback(async () => {
    setLoading(true);
    const res = (await saveOrderForLater(finalOrder, {
      total: payableAmt,
      businessInfo,
    })) as OrderSubmissionResponse;

    setLoading(false);

    if (!res.success) {
      setPaymentError(res as string);
      return;
    }
    if (res) {
      history.push(`/order/${res.orderTrackingId}`);
    }
  }, [payableAmt, finalOrder, businessInfo]);

  const SaveForLater = useMemo(
    () => (
      <AppButton isOutlined onClick={()=>setSaveForLaterConfirm(true)}>Save Order for Later</AppButton>
    ),
    []
  );

  const DeliveryRow = useMemo(() => {
    if (!isDeliveryOrder) {
      return null;
    }

    const deliveryRowOptions: PaymentRowOptions = {
      replaceAmountLabel: freeDeliveryLabel,
      isPositive: finalOrder.deliveryFee === 0,
    };
    return (
      <PaymentRow
        label="Delivery"
        value={finalOrder.deliveryFee}
        {...deliveryRowOptions}
      />
    );
  }, [isDeliveryOrder, finalOrder.deliveryFee]);

  const hasCredit = !!creditDiscount && creditDiscount.value > 0;
  const isPayNothing = payableAmt === 0;
  const canUseAllCredits = isSignedIn && hasCredit && isPayNothing;

  const StoreCreditRow = useMemo(() => {
    if (!hasCredit || !isGiftCardPurchasable) {
      return null;
    }

    const storeCreditOptions: PaymentRowOptions = { isDiscount: true };
    return (
      <PaymentRow
        label="Used Store credit"
        value={creditDiscount?.amount}
        {...storeCreditOptions}
      />
    );
  }, [hasCredit, isGiftCardPurchasable, creditDiscount]);

  const HighestDiscountRow = useMemo(() => {
    if (!highestDiscount) {
      return null;
    }
    const isPercentage =
      highestDiscount.type === DISCOUNT_VALUE_TYPE.percentage;
    const percent = isPercentage ? highestDiscount.value : undefined;

    const discountAmt = highestDiscount?.amount ?? 0;
    const isDiscount = discountAmt >= 0;
    const value = isDiscount ? highestDiscount.amount : -discountAmt;

    return (
      <PaymentRow
        label={highestDiscount?.title}
        value={value}
        percent={percent}
        isDiscount={isDiscount}
      />
    );
  }, [highestDiscount]);

  const tipOptions: PaymentRowOptions = {
    percent: finalOrder.selectedTipPercentage,
  };
  const deliveryTipOptions: PaymentRowOptions = {
    percent: finalOrder.driverTipPercentage,
  };

  return (
    <>
    <div className={classes.pay_in_person}>
      {isLoading && <Loader />}

      <PaymentRow
        ref={myRef}
        label="Amount"
        value={finalOrder.subTotal}
        bold={true}
      />

      {HighestDiscountRow}
      {SurchargeRows}
      {DeliveryRow}
      {PaperBagRow}

      <PaymentRow label="Tax" value={finalOrder.tax} />
      <PaymentRow
        label={isDeliveryOrder ? "Staff Tip" : "Tip"}
        value={tip}
        {...tipOptions}
      />
      {isDeliveryOrder && (
        <PaymentRow
          isDriver
          label="Driver Tip"
          value={driverTip}
          {...deliveryTipOptions}
        />
      )}
      <PaymentRow label="Total" value={finalOrder.total} />
      {StoreCreditRow}
      <PaymentRow label="Payable amount" value={payableAmt} bold={true} />

      <div className={classes.pay_in_person__buttons}>
        <Typography color="error">{paymentError}</Typography>
        {isKioskViewMode ? (
          <>
            <KioskOrderConfirmationPage
              isOpen={showSubmittedPopup}
              autoCloseRemainingTime={autoCloseRemainingTime}
              orderNum={orderNum}
              payableAmt={payableAmt}
              onClose={onClosePopup}
            />
            {PayInPersonButton}
            {SaveForLater}

            {EditOrderButton}
          </>
        ) : (
          <>
          <Stack spacing={2}>
          {!showCreditCardForm ? (
              canUseAllCredits ? (
                <CreditPayButton
                  handleOrderSubmission={handleOrderSubmission}
                  payableAmt={payableAmt}
                />
              ) : (
                <>
                  {CreditCardPayModes}
                  {(!isDeliveryOrder && isPayInPersonEnabled) && PayInPersonButton}
                </>
              )
            ) : (
              <></>
            )}
          </Stack>
           
            <Stack spacing={2}>
              {!showCreditCardForm && SaveForLater}
              {EditOrderButton}
            </Stack>

            {showCreditCardForm ? (
              <CreditCardPayForm total={payableAmt} />
            ) : (
              <></>
            )}
          </>
        )}
      </div>

    </div>
          <ConfirmSaveForLater open={saveForLaterConfirm} onCancel={()=>setSaveForLaterConfirm(false)} onOk={handleSaveForLater} /> 
</>
  );
};

export default ConfirmOrder;
