import { CSSProperties } from "react";

export const isDebug = false;

export const defaultRequestPlaceholder = "e.g. well-done, less spicy, no onion";

export const alternatePhone = {
  placeholder: "Enter phone number for a verification sms",
  title: "Did you enter a wrong number?",
};

export enum StoreOrderingState {
  preOrdersOnly = "preOrdersOnly",
  allOrdersTypes = "allOrdersTypes",
  closed = "closed",
  preOrdersOnlyTodayOff = "preOrdersOnlyTodayOff",
}

export enum OrderMode {
  normal = "normal",
  table = "table",
  kiosk = "kiosk",
  menuOnly = "menu",
  catering = "catering",
}

export enum AddCondition {
  all = "all",
  signedIn = "signedIn",
}

export enum OptionType {
  all = "all",
  giftCard = "giftCard",
  eventTicket = "eventTicket",
}

export const promoCodeKey = "promoCode";
export const userPhoneCookieKey = "userPhone";
export const userCustomerNameCookieKey = "userCustomerName";
export const deliveryInfoCookieKey = "deliveryInfo";
export const deliveryNameCookieKey = "deliveryName";
export const deliveryUnitNumberKey = "deliveryUnitNumber";
export const deliveryInstructionKey = "deliveryInstructions";
export const smsNotificationReqKey = "smsNotificationRequested";
export const userIdKey = "userId";
export const userEmailKey = "userEmail";

export const orderTypeMap = {
  takeout: "take-out",
  preorder: "pre-order",
  dinein: "dine-in",
  delivery: "delivery",
};

export const orderTypeLabelMap = {
  "take-out": "Take-out",
  "pre-order": "Pre-order",
  "dine-in": "Dine-in",
  delivery: "Delivery",
};

export const defaultExcludedCities = "";

export const defaultDiningOption = "takeout";
export const diningOptionValues = Object.values(orderTypeMap);

export const snackbarTimeoutInMillis = 1000 * 30; // 1 sec * 30
export const daysSelectOptionsCount = 10;

export const dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const giftActivationError =
  "The gift card was not found or it has already been activated. If you have any questions, contact support@relayfy.com";

export const cancelNewOrderError =
  "The order is already in processing status and cannot be cancelled online";

export const giftCardPurchaseWarningMessage =
  "You can't use the store credit to buy a gift card.";
export const discountNotApplicableWarningMessage =
  "Note: Gift Cards and Event Tickets are not part of overall offers.";

export const featuredStyle: CSSProperties = {
  background: "linear-gradient(to bottom, #ffe7ba, #fff6de, #ffe7ba)",
};

export const deliveryUnitFieldLabel = "Apt / unit number (opt)";
export const deliveryInstructionsLabel = "Delivery instructions (opt)";

export const deliveryInstructionsHelperText = "e.g. gate code, landmarks";

export const deliveryAddressPlaceholder = "Type your address";
export const deliveryUnitPlaceholder = "Unit #1234";
export const deliveryInstructionsPlaceholder =
  "Delivery Instruction (optional)";

export const phoneInvalidErrorMessage = "Please enter a valid phone number!";
export const phoneRequiredMessage = "Enter phone if you need an invoice";
export const nameMissingErrorMessage = "Please enter a valid phone number!";
export const deliveryDistanceFetchErrorMessage =
  "There was an error retrieving the distance information. Please reach out to administrators for more info.";
export const addressFieldRequiredMessage =
  "Address fields required for delivery orders.";
export const deliveryError =
  "Unfortunately delivery service is not available currently, you may order for takeout only.";

export const freeDeliveryLabel = "Free Delivery";
export const noPaperBagOrderTypes = [
  orderTypeMap.delivery,
  orderTypeMap.dinein,
];
